@import '~react-agenda/build/styles.css';
@import '~react-datetime/css/react-datetime.css';

.select-search {
  position: relative;
  font-family: 'Nunito Sans', sans-serif;
  
}

.hidden {
  display: none;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

/**
* Value wrapper
*/
.select-search__value {
  position: relative;
  z-index: 1;
}

.select-search__value::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

/**
* Input
*/
.select-search__input > * {
  max-width: calc(100% - 56px);
}

.select-search__input {
  display: block;
  height: 36px;
  width: calc(100% - 56px);
  padding: .5rem 40px .5rem 16px;
  background: #fff;
  border: 1px solid transparent;
  box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
* Options wrapper
*/
.select-search__select {
  background: #fff;
  box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
}

/**
* Options
*/
.select-search__options {
  list-style: none;
}

/**
* Option row
*/
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
* Option
*/
.select-search__option,
.select-search__not-found {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: #0B5AAD;
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: rgba(47, 204, 139, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #0B5AAD;
  color: #fff;
}

/**
* Group
*/
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
* States
*/
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
* Modifiers
*/
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: #0B5AAD;
}


.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}

.duplex-dashboard-perfil {
  display: flex;
}

.duplex-dashboard-perfil {
  display: flex;
}

.mi-info-personal-content {
  position: relative;
  padding-top: 60px;
  width: 25rem;
}

.mi-info-personal {
  box-shadow: 0 0 15px #888;
  width: calc(100% - 2rem);
  padding: 1rem;
  padding-top: 125px;
  text-align: center;
  border-radius: 8px;
  position: relative;
  background: white;
}

.mi-info-personal .img {
  width: 100%;
  padding: 0 !important
}

.mi-info-personal img {
  border-radius: 50%;
  z-index: 9;
  width: 100%
}

.cancel-area {
  position: absolute;
  top: 0;
  z-index: 0;
  left: 40%;
  right: 40%;
  transition: all .5s;
}

.mi-info-personal:hover .cancel-area {
  top: 30px !important;
  left: auto !important;
  right: 25% !important;
}

.hide {
  display: none !important
}

.mi-info-personal .img {
  border-radius: 50%;
  position: absolute;
  top: -50px;
  left: calc(50% - 70px);
  box-shadow: 0 0 15px #999;
  width: 140px;
  height: 140px;
}


.content-forms-perfil {
  width: calc(100% - 27rem);
  margin-left: auto;
}

.content-forms-perfil > div {
  display: flex;
  margin-top: .8rem;
}

.content-forms-perfil > div > form {
  width: calc(50% - 6rem);
  box-shadow: 0 0 15px #ccc;
  margin: 1rem auto;
  padding: 2rem;
  background: white;
  border: 1px dashed #0B5AAD;
}

.content-forms-perfil > div > form > * {
  width: 100%;
  margin: .5rem 0;
}


.group-btns-modal {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content-div {
  width: 20rem;
  max-width: 90%;
  background: white;
  padding: 2rem;
  display: block;
  margin: auto;
}

.modal-content-div #server-modal-title {
  margin: 0;
  text-align: center;
  margin-bottom: 1rem;
}

.modal-content-div form > * {
  width: 100%
}

.agenda__cell > div ,
.agenda__cell > div > div {
  width: 100%;
  text-align: center;
  color: white;
}

.agenda-cell-item:hover {
  z-index: 999 !important;
}

.duration {
  color: red;
  font-size: 11px;
  text-align: center;
}

.agenda-controls-item button {
  border: none;
  padding: 5px;
  cursor: pointer;
}

.floating-btn-users {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  background: #23A2AB;
  z-index: 99;
  border-radius: 50%;
}

.alert-formulario {
  position:fixed;
  right: 1rem;
  top: 1rem;
  z-index: 9999999
}

.form-edit {
  width: 40rem;
  max-width: 80%;
  border: 1px dashed #23A2AB;
  margin: auto;
  padding: 2rem;
  border-radius: 8px
}

.form-edit > * {
  width: 100%
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.perfil-head {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.perfil-head h1 {
  margin-bottom: 5px
}

.perfil-head h3 {
  margin: 0;
  font-weight: lighter;
}

.perfil-head h4 {
  margin: 0;
}

.historia-card {
  width: calc(100% - 2rem);
  padding: 1rem;
}

.option-colored:hover {
  background: #ff9500 -webkit-linear-gradient(bottom, #ff9500 0%, #ff9500 100%) !important;
  color: #fff !important;
 }

.cards-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.cards-content .card {
  border: 2px solid #23A2AB;
  margin: .5rem;
  padding: 1rem 2rem;
  position: relative;
}

.cards-content .del {
  position: absolute;
  right: 0;
  top: 0;
}

.cards-content .del > * {
  color: #f00
}

.cards-content b {
  color: #23A2AB;
}

.modal-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent
}

.modal-2 .white {
  border: none;
  background: white;
  width: 60%;
  padding: 2rem;
  position: relative;
  max-height: 85%;
  overflow-y: auto;
  border-radius: 8px
}

.modal-2 .white-print {
  border: none;
  background: white;
  width: 60%;
  padding: 2rem;
  position: relative;
  max-height: 85%;
  overflow-y: auto;
  border-radius: 8px
}

.modal-2 .white:focus {
  outline: none;
}

.modal-2 .white button:focus {
  outline: none;
}

.modal-2 .white-print:focus {
  outline: none;
}

.modal-2 .white-print button:focus {
  outline: none;
}

.form-signup {
  padding: 2rem;
  border-radius: 8px;
  display: inline-block;
  width: calc(90% - 4rem );
  margin-left: 5%;
  overflow-y: auto;
}

.form-signup .inp {
  width: 100%;
}

.form-signup .send-btn {
  width: 100%;
}

.dashboard {
  padding: 2rem;
  padding-top: 5rem;
  overflow-x: hidden;
}

.agenda__table {
  height:auto;
}

.agenda {
  border:1px solid #ccc;
  box-shadow: 0 0 15px #ddd
}

/*** Inicio de estilos de contenedor de formularios ***/
.duplex-content-drawer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.duplex-content-drawer .preview-content {
  width: calc(50% - 2rem);
  padding: 1rem;
  position: relative;
}

.duplex-content-drawer .preview-content form {
  box-shadow: 0 0 15px #ccc;
  padding: 1rem;
  background-color: white;
}

.duplex-content-drawer .preview-content .menu-btn {
  position: absolute;
  top: 2rem;
  right: 2rem
}
.user-info-drawer-content {
  width: calc(300px - 2rem);
  height: calc(250px - 2rem);
  background: #23A2AB;
  background: -webkit-linear-gradient(to right, #1B87F9, #23A2AB);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #1B87F9, #23A2AB); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  padding: 1rem;
  padding-top: 5rem;
  background-image: url('./media/portada2.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.user-info-drawer-content img {
  width: 100px !important;
  height: 100px !important;
  border-radius: 50%;
}

.user-info-drawer-content h4,
.user-info-drawer-content h6 {
  color: #444;
  margin: 3px 0
}

.control-buttons button {
  margin: .2rem;
}

.MuiDrawer-paper {
  width: 300px;
  overflow-x: hidden;
}

.ul-menu-cotent {
  padding: 1rem;
  width: calc(300px - 2rem);
  height: 100%;
}

.ul-menu-cotent li {
  list-style: none;
  margin: 0;
  width: inherit;
  display: inline-flex;
  margin: 5px 0;
}

.ul-menu-cotent li button {
  background: transparent;
  border: none;
  cursor: pointer;
}

.ul-menu-cotent li button:focus {
  outline: none;
  border: none;
}

.ul-menu-cotent li a,
.ul-menu-cotent li button {
  padding: 10px;
  width: calc(300px - 2rem);
  text-decoration: none;
  color: #444;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ul-menu-cotent li a > *,
.ul-menu-cotent li button > * {
  margin-right: 10px
}

.ul-menu-cotent li a:hover,
.ul-menu-cotent li button:hover,
.ul-menu-cotent li .active {
  color: #23A2AB;
  border-left : 3px solid #23A2AB;
}

@media (max-width : 1200px) {
  .modal-2 .white-print {
    width: 80%;
    padding: 2rem;
  }
}

@media (max-width : 1000px) {
  .content-forms-perfil > div {
    display: block;
  }
  .content-forms-perfil > div > form {
    width: calc(100% - 6rem);
    box-shadow: 0 0 15px #ccc;
    margin: 1rem auto;
    padding: 2rem;
    background: white;
    border: 1px dashed #0B5AAD;
  }
}

@media (max-width :980px) {
  .modal-2 .white-print {
    width: 90%;
    padding: 1rem;
  }
}

@media (max-width :870px) {
  .modal-2 .white-print {
    width: 95%;
    padding: 0.5rem;
  }
}

@media (max-width:750px) {
  .duplex-content-drawer {
    display: block;
  }

  .duplex-content-drawer > * {
    width: calc( 100% - 2rem ) !important
  }
  .form-signup {
    width: calc(95% - 4rem );
    margin-left: 2.5%;
    overflow-y: auto;
  }

  .modal-2 .white-print {
    width: 95%;
    padding: 1rem;
  }

  .modal-2 .white-print .head-pdf-print > div  {
    width: 100% !important;
    display: block !important;
  }

  .modal-2 .white-print .head-pdf-print > div > div {
    width: 100% !important
  }

  .modal-2 .white {
    border: none;
    background: white;
    width: 90%;
    padding: .5rem;
    position: relative;
    max-height: 85%;
    overflow-y: auto;
    border-radius: 8px
  }

  .duplex-dashboard-perfil {
    display: block;
  }

  .duplex-dashboard-perfil > * {
    width: 100%;
  }

}
